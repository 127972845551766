import React from "react";
import { graphql } from "gatsby";
import { document } from "browser-monads";
import { useSize } from "ahooks";

// Components
import AboutAuthor from "../../components/aboutAuthor";
import BlockContent from "../../components/richText";
import FurtherReadingLinks from "../../components/furtherReadingLinks";
import Form from "../../components/form";
import Hero from "../../components/hero";
import HeroRedesigned from "../../components/heroRedesigned";
import Layout from "../../components/layout";
import SideMenu from "../../components/sideMenu";
import ShareComponent from "../../components/shareComponent";
import Seo from "../../components/seo/seo";

// Constants
import API from "../../constants/api";
import BREAKPOINTS from "../../constants/breakpoints";

// Helpers
import generateRandomId from "../../helpers/generateRandomId";
import { getVideoDataInPage } from "../../helpers/getVideoDataInPage";

// Hooks
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import useCategoriesList from "../../hooks/useCategoriesMenu";

// Styles
import * as styles from "../blogsStyles.module.scss";

function BlogPost({ data, pageContext }) {
  const size = useSize(document.querySelector("body"));

  const { allSanityBlogCategory, sanityBlogPost, sanityBlogListing } = data;

  const authorContent = sanityBlogPost?.tabs?._rawContent.author;
  const heroContent = sanityBlogPost.tabs._rawContent.hero;
  const richTextContent = sanityBlogPost.tabs._rawContent?.richText;
  const furtherReadingValue = sanityBlogPost.tabs._rawContent?.furtherReading;
  const formContent = sanityBlogPost.tabs._rawContent.form;
  const noForm = sanityBlogPost.tabs._rawContent.noForm;
  const seo = sanityBlogPost?.tabs?._rawSeo;
  const heroTitle = heroContent.heroText.titleText;
  const publishDate = sanityBlogPost.tabs._rawContent.publishDate;
  const { _updatedAt } = sanityBlogPost;
  const mainCategoryName = sanityBlogListing?.tabs.content.catCustomName;
  const HeroComponent = heroContent?.isRedesigned ? HeroRedesigned : Hero;

  const video = getVideoDataInPage(richTextContent);

  const categoriesList = useCategoriesList(
    API.blog,
    allSanityBlogCategory.nodes,
    mainCategoryName
  );

  const breadcrumbsList = [
    {
      _key: generateRandomId(),
      urlLabel: mainCategoryName ?? "All Categories",
      url: API.blog.slice(1),
    },
    ...categoriesList.slice(1),
  ];

  const breadcrumbs = useBreadcrumbs(breadcrumbsList, false);

  return (
    <Layout stickyHeader isPage>
      <Seo
        title={heroTitle}
        image={heroContent?.bgImage}
        author={authorContent?.name}
        breadcrumbs={breadcrumbs}
        isBlogPost
        path={pageContext?.pagePath}
        datePublished={publishDate}
        dateModified={_updatedAt}
        video={video}
        {...seo}
      />
      <HeroComponent
        imageClassName={styles.heroImg}
        breadcrumbs={breadcrumbs}
        image={heroContent?.bgImage}
        subTitle={heroContent?.heroText?.subtitleText}
        title={heroContent?.heroText.titleText}
      />
      <div className={styles.mainContentWrapper}>
        <div className={styles.sideMenuWrapper}>
          <SideMenu
            getPathFunc={API.blogCategory}
            hasMarks
            hasSharing
            list={categoriesList}
          />
        </div>
        <div className={styles.richText}>
          {richTextContent && <BlockContent blocks={richTextContent} />}
          {authorContent && <AboutAuthor author={authorContent} />}
        </div>
      </div>
      <div className={styles.bottomContentWrapper}>
        {size?.width < BREAKPOINTS.lg && <ShareComponent />}
        <FurtherReadingLinks {...furtherReadingValue} />
        {!noForm && <Form {...formContent} />}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    sanityBlogPost(tabs: { content: { slug: { current: { eq: $slug } } } }) {
      _updatedAt(formatString: "MMMM Do, YYYY")
      tabs {
        _rawContent(resolveReferences: { maxDepth: 10 })
        _rawSeo(resolveReferences: { maxDepth: 10 })
      }
    }
    allSanityBlogCategory {
      nodes {
        catColor
        _rawSlug
        title
      }
    }
    sanityBlogListing {
      tabs {
        content {
          catCustomName
        }
      }
    }
  }
`;

export default BlogPost;
