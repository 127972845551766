// Modules
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Link } from "gatsby";

// Components
import Button from "../button";
import CustomGatsbyImage from "../gatsbyImage";

// Constants
import COLORS from "../../constants/colors";
import API from "../../constants/api";

// Styles
import * as styles from "./furtherReadingLinks.module.scss";

const FurtherReadingCard = ({ background, cardInfo }) => {
  const { tabs, _type } = cardInfo;
  const { content } = tabs;

  const TYPES = {
    cs: {
      title: "CASE STUDY",
      color: COLORS.pink,
      heroImage: () => content.hero.bgImage.asset,
      key: "csCard",
      linkFunction: () =>
        API.caseStudiesCard(
          content.category.slug.current,
          content.slug.current
        ),
    },
    csCard: {
      title: "CASE STUDY",
      color: COLORS.pink,
      heroImage: () => content.hero.bgImage.asset,
      key: "csCard",
      linkFunction: () =>
        content?.isLanding
          ? API.landing(content.slug.current)
          : API.caseStudiesCard(
              content.category.slug.current,
              content.slug.current
            ),
    },
    blogCard: {
      title: content.category.title,
      color: COLORS.pink,
      heroImage: () => content.category.hero.bgImage.asset,
      key: "blogCard",
      linkFunction: () =>
        API.blogPost(content.category.slug.current, content.slug.current),
    },
    blogPost: {
      title: content.category.title,
      color: COLORS.pink,
      heroImage: () => content.category.hero.bgImage.asset,
      key: "blogCard",
      linkFunction: () =>
        API.blogPost(content.category.slug.current, content.slug.current),
    },
    evCard: {
      title: content.category.title,
      color: COLORS.pink,
      heroImage: () => content.category.hero.bgImage.asset,
      key: "evCard",
      linkFunction: () =>
        content?.isLanding
          ? API.landing(content.slug.current)
          : API.evPost(content.category.slug.current, content.slug.current),
    },
    ev: {
      title: content.category.title,
      color: COLORS.pink,
      heroImage: () => content.category.hero.bgImage.asset,
      key: "evCard",
      linkFunction: () =>
        API.evPost(content.category.slug.current, content.slug.current),
    },
    rs: {
      title: content.category.title,
      color: COLORS.pink,
      heroImage: () => content.category.hero.bgImage.asset,
      key: "rsCard",
      linkFunction: () =>
        API.resourcesPost(content.category.slug.current, content.slug.current),
    },
    rsCard: {
      title: content.category.title,
      color: COLORS.pink,
      heroImage: () => content.category.hero.bgImage.asset,
      key: "rsCard",
      linkFunction: () =>
        content?.isLanding
          ? API.landing(content.slug.current)
          : API.resourcesPost(
              content.category.slug.current,
              content.slug.current
            ),
    },
  };
  const actualKey = TYPES[_type]?.key;
  const data = tabs[actualKey];
  return (
    <div className={styles.columnsCard}>
      <Link to={TYPES[_type]?.linkFunction()}>
        <div className={styles.imageWrapper}>
          <div
            className={styles.triangle}
            style={{
              borderLeft: `64px solid ${TYPES[_type]?.color}`,
            }}
          />
          <div className={styles.image}>
            {data && data?.image ? (
              <CustomGatsbyImage
                alt="card preview"
                img={data.image.asset}
                height={215}
                width={285}
              />
            ) : (
              <CustomGatsbyImage
                alt="card preview"
                img={TYPES[_type]?.heroImage()}
                height={215}
                width={285}
              />
            )}
          </div>
          <div
            className={cx({
              [styles.whiteBack]: background !== "#EDEDED",
              [styles.greyBack]: background === "#EDEDED",
            })}
          />
        </div>
        <div className={styles.h4} style={{ color: TYPES[_type]?.color }}>
          {TYPES[_type]?.title.toUpperCase()}
        </div>
        {content?.title && <h3>{content.title}</h3>}
        {data?.excerpt && <p>{data.excerpt}</p>}
      </Link>
      <div className={styles.readMore}>
        <Button
          classNameBtn="raleway-xxs-bold"
          label={data?.buttonText ?? "Read more"}
          link={TYPES[_type]?.linkFunction()}
        />
      </div>
    </div>
  );
};

FurtherReadingCard.propTypes = {
  background: PropTypes.string,
  cardInfo: PropTypes.shape({
    _type: PropTypes.string,
    tabs: PropTypes.object,
  }).isRequired,
};

FurtherReadingCard.defaultProps = {
  background: "",
};

export default FurtherReadingCard;
