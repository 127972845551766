// Modules
import React from "react";
import PropTypes from "prop-types";

// Components
import FurtherCard from "./furtherReading";

// Styles
import * as styles from "./furtherReadingLinks.module.scss";

const FurtherReadingLinks = ({ background, cards, titleSubtitle }) => {
  if (!cards.length) return null;

  const wrapperStyle = { backgroundColor: background };

  return (
    <div className={styles.wrapper} style={wrapperStyle}>
      {titleSubtitle && (
        <div className={styles.fRHeader}>
          {titleSubtitle?.titleText && (
            <div className={styles.title}>{titleSubtitle.titleText}</div>
          )}
          {titleSubtitle?.subtitleText && (
            <div className={styles.subtitle}>{titleSubtitle.subtitleText}</div>
          )}
        </div>
      )}
      <div className={styles.columnsRow}>
        {cards.map((card, index) => (
          <FurtherCard
            background={background}
            cardInfo={card}
            key={index.toString()}
          />
        ))}
      </div>
    </div>
  );
};

FurtherReadingLinks.propTypes = {
  background: PropTypes.string,
  cards: PropTypes.array.isRequired,
  titleSubtitle: PropTypes.oneOfType([
    PropTypes.shape({
      subtitleText: PropTypes.string,
      titleText: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
};

FurtherReadingLinks.defaultProps = {
  background: "",
  titleSubtitle: false,
  cards: [],
};

export default FurtherReadingLinks;
